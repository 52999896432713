import React, {useContext, useState} from 'react';
import CardIcon from "../Option2/images/icon-card-small.png";
import {Context} from "../../index";
import {NavLink, Redirect, useRouteMatch} from "react-router-dom";
import {PAGE_FIVE, PAGE_TWO,} from "../../routes/consts";
import NavLinkIconBack from "../../components/NavLinkIconBack";
import {observer} from "mobx-react-lite";
import {usePaymentInputs} from "react-payment-inputs";
import CarrotVideoBox from "../../components/CarrotVideoBox";


const PageTwoOption3 = observer(() => {
    const {page} = useContext(Context);
    const url = '/' + page.isUrl;
    const match = useRouteMatch();
    const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs();
    const [dateError, setDateError] = useState(false);
    const [dateErrorCardNumber, setDateErrorCardNumber] = useState(false);
    
    if (page.amount == '')
        return <Redirect to={match + PAGE_TWO}/>;
    
    const isDateGreaterThanToday = (date) => {
        date = date.replace(/\s/g, '');
        let dS = date.split("/");
        let d1 = new Date('20' + dS[1], (+dS[0] - 1));
        let today = new Date();
        setDateError(!(d1 > today))
    }
    
    const handleChangeCardNumber = e => {
        setDateErrorCardNumber(e.target.value.length !== 19)
        page.setCardNumber(e.target.value)
    }
    
    const handleChangeExpiryDate = e => {
        if(e.target.value.length == 7)
            isDateGreaterThanToday(e.target.value)
        page.setCardDate(e.target.value)
    }
    
    const handleChangeCVC = e => {
        page.setCardCvc(e.target.value)
    }
    
    return (
        <div className='wrapper'>
            <CarrotVideoBox/>
            <div className="wrapper-bg wrapper-bg-image w-100 d-flex align-items-start flex-column">
                <div className="container" style={{padding: '100px 50px 40px 50px'}}>
                    <NavLinkIconBack
                        url={url + PAGE_TWO}
                    />
                    <div className="title">
                        <h1>Payment details</h1>
                    </div>
                    <div className={`w-100 card-form d-flex justify-content-between align-items-center ${((meta.isTouched && meta.error) || dateError || dateErrorCardNumber) ? 'border-red' : ''}`}>
                        <img src={CardIcon} height='25' width='37' style={{marginRight: '15px'}} />
                        <input{...getCardNumberProps({ onChange: handleChangeCardNumber })} value={page.cardNumber} type="number"/>
                        <input style={{width: '75px'}} {...getExpiryDateProps({ onChange: handleChangeExpiryDate })} value={page.cardDate} type="number"/>
                        <input style={{width: '75px'}} {...getCVCProps({ onChange: handleChangeCVC })} value={page.cardCvc} type="number"/>
                    </div>
                    <div className='my-4'>
                        {meta.isTouched && meta.error && <span style={{color: 'red'}}>Error: {meta.error}</span>}
                        {!(meta.isTouched && meta.error) && dateError && <span style={{color: 'red'}}>Error: Expiry date cannot be in the past</span>}
                    </div>
                    <div className='card-text w-100 my-5 p-relative'>
                        There was a problem processing your payment please try again with a different card.
                    </div>
                    <div className='w-100 text-center'>
                        <NavLink
                          style={(
                            (page.cardNumber.length == 19) && (page.cardDate.length >= 7) && (page.cardCvc.length >= 3) && !(meta.isTouched && meta.error) && !dateError && !dateErrorCardNumber
                          ) ? null : {opacity: "0.7"}}
                          className={`btn btn-secondary w-75`}
                          to={url + PAGE_FIVE}>Next</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PageTwoOption3;