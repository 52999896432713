export const MAIN_ROUTE = "/";
export const CURRENCY_PAGE = "/currency";
export const PAGE_ONE = "/amount-donate";
export const PAGE_PAYMENT_GATEWAY = "/payment-gateway";
export const PAGE_TWO = "/swipe-card";
export const PAGE_THREE = "/payment-details";
export const PAGE_FIVE = "/personal-information";
export const PAGE_SIX = "/check";
export const PAGE_DONE = "/done";
export const PAGE_ERROR = "/error";
