import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";

const BackgroundOption3 = observer((props) => {
  const {page} = useContext(Context);
  return (
    <div
      className="wrapper-bg wrapper-bg-image w-100 d-flex align-items-start flex-column"
      style={{  backgroundImage: `url(${page.imageBackground})`}}
    >
      {props.children}
    </div>
  );
});

export default BackgroundOption3;