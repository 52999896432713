import React from "react";

export class InputDecimal extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      input: this.props.page.amount ? Number(this.props.page.amount) : "",
    };
    this.start = 0;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.amount !== this.props.amount) {
      this.setState({ input: Number(this.props.amount) });
    }
  }

  change = (e) => {
    this.start = e.target.selectionStart;
    console.log(" e.target.selectionStart;", e.target.selectionStart);
    console.log("s", this.start);
    let val = e.target.value;
    // val = val.replace(/([^0-9.]+)/, "");
    // val = val.replace(/^(0|\.)/, "");
    // const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
    // const value = match[1] + match[2];
    // console.log(val, value);
    // e.target.value = value;
    // this.setState({ input: value });
    // this.props.page.setAmount(value);
    // if (val.length > 0) {
    e.target.value = Number(val);
    // e.target.setSelectionRange(this.start, this.start);
    const tempResult = Number(val);
    this.setState({ input: tempResult });
    this.props.page.setAmount(tempResult);
    // }
  };

  render() {
    const { className } = this.props;
    return (
      <div className="w-100" style={{ position: "relative" }}>
        <input
          className={className}
          type="text"
          onBlur={this.blur}
          onChange={this.change}
          value={this.state.input}
          {...this.props}
        />
      </div>
    );
  }
}
