import React, {useContext, useState} from 'react';
import './style.css';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import { CSSTransition } from 'react-transition-group';

const VideoBox = observer(() => {
  const {page} = useContext(Context);
  const [showButton, setShowButton] = useState(false);
  const [buttonClass, setButtonClass] = useState(false);
  
  const handleClick = () => {
    const video = document.getElementById("myVideo");
    if (video.paused) {
      video.play();
      setButtonClass('play');
      setShowButton(true);
      setTimeout(()=>setShowButton(false), 1000);
    } else {
      video.pause();
      setButtonClass('paus');
      setShowButton(true);
      setTimeout(()=>setShowButton(false), 1000);
    }
  }
  
  return (
    <div className='video-box'>
      {
        (page.imageBackground && page.imageBackground.indexOf('.mp4') != -1) ?
        <>
          <video autoPlay muted loop id="myVideo"  onClick={handleClick}  >
            <source
              src={page.imageBackground}
              type="video/mp4"
            />
          </video>
          <CSSTransition
            in={showButton}
            timeout={300}
            classNames="alert"
            unmountOnExit
          >
          <div className={buttonClass}></div>
          </CSSTransition>
        </> :
          <div className='video-box-bg'
               style={{
                 backgroundImage: `url(${page.imageBackground})`
                //  backgroundImage: `url(https://api.ppkiosk.com/storage/background/QUUOQA5RF74GGax2x83wVg0CLqlNXQB1fuOe4et0.jpg)`
               }}
          ></div>
      }
    </div>
  )
})

export default VideoBox;
