import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import SlugRoute from "./SlugRoute";

const AppRouter = () => {
  return (
    <Switch>
      <Route path={`/:url`} children={<SlugRoute />} />
      <Route path={`/404`} component={<div>404</div>} />
      <Redirect to={"/404"} />
    </Switch>
  );
};

export default AppRouter;
