import {MAIN_ROUTE} from "../../routes/consts";
import {NavLink} from "react-router-dom";
import React from "react";
import * as PropTypes from "prop-types";

const NavLinkIconBack = ({url}) => (
    <NavLink className='a-icon-back' to={url}>
        <div className='icon-back'></div>
    </NavLink>
)

NavLinkIconBack.prototype = {
    url: PropTypes.string,
};

NavLinkIconBack.defaultProps = {
    url: MAIN_ROUTE,
};

export default NavLinkIconBack;