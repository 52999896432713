// code from https://github.com/aaronglang/MagStripeJS/blob/master/src/parsers.js

const map_card_data = (matches) => {
  let obj = {};
  matches.map((x) => {
    if (/[\sa-z]+\/[\sa-z]+/gi.test(x)) {
      // get name
      let y = x.trim().split("/");
      obj.first_name = y[1].split(".").join("").trim().toLowerCase();
      obj.last_name = y[0].split(".").join("").trim().toLowerCase();
    } else if (/^\d{13,20}$/gi.test(x)) {
      // get card number
      obj.card_number = x.trim();
    } else if (/^\d{4}/gi.test(x)) {
      // check expiration
      let exp_data = check_card_expiration(x);
      Object.assign(obj, exp_data);
    }
  });
  return obj;
};

const check_card_expiration = (str) => {
  let obj = {};
  obj.expiration_date = str.trim().match(/\d{2}/g).reverse().join("/");
  let now = new Date();
  let expiration_date = new Date(
    +`20${obj.expiration_date.substr(3, 2)}`,
    +obj.expiration_date.substr(0, 2) - 1
  );
  let expired = date_diff_days(expiration_date, now);
  obj.expired = !!(expired > 0);
  return obj;
};

const date_diff_days = (date_1, date_2) => {
  // get time diff
  let time_diff = date_2.getTime() - date_1.getTime();
  // return diff in days
  return Math.ceil(time_diff / (1000 * 3600 * 24));
};

//export const parse_card = (str) => {
//  if (typeof str !== "string") return null;
//  let regexp =
//    /((?<=%b)\d+(?=\^))|((?<=\^)[\w\/.?\s]+(?=\^))|((?<=[=\^])\d{4})/gim;
//  let matches = str.match(regexp);
//  if (matches && matches.length > 2) {
//    return map_card_data(matches);
//  } else {
//    return null;
//  }
//};

export const parse_card = (str) => {
  if (typeof str !== "string") return null;
  let card = str.match(
    /%B([0-9]+)\^([A-Z /.]+)\/([A-Z /.]*)\^([0-9]{2})([0-9]{2})/
  );

  //  let lastName = card[2].trim();
  //  // 'LASTNAME/FIRSTNAME.MR' is possible
  //  let firstName = card[3].trim().split(".")[0];

  let firstName = card[3]?.substring(0, card[3]?.indexOf(" "));
  let lastName = card[2]?.substring(card[2]?.indexOf(" ") + 1);

  if(!card[3] || card[3] == '') {
    firstName = card[2]?.substring(0, card[2]?.indexOf(" "));
  }

  let fullName = `${lastName}/${firstName} `;

  let cd = [card[1], fullName, card[4] + card[5]];

  let matches = Object.values(cd);

  //alert(matches.join());
  if (matches && matches.length > 2) {
    return map_card_data(matches);
  } else {
    return null;
  }
};
