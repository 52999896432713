import React, {useContext, useState} from 'react';
import './style.css';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";

const CarrotVideoBox = observer(() => {
  const {page} = useContext(Context);
  
  const handleClick = () => {
    const video = document.getElementById("myVideo");
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  }
  
  return (
    <div className='carrot-video-box'>
      {
        (page.imageBackground && page.imageBackground.indexOf('.mp4') != -1) ?
        <>
          <video autoPlay muted loop id="myVideo"  onClick={handleClick}  >
            <source
              src={page.imageBackground}
              type="video/mp4"
            />
          </video>
        </> :
          <div className='carrot-video-box-bg'
               style={{
                 backgroundImage: `url(${page.imageBackground})`
               }}
          ></div>
      }
    </div>
  )
})

export default CarrotVideoBox;
