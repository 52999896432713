import {makeAutoObservable} from "mobx";

export default class PersonalInformationStore {
    constructor() {
        this._firstName = '';
        this._lastName = '';
        this._email = '';
        this._phone = '';
        this._message = '';
        makeAutoObservable(this);
    }

    setClear() {
        this._firstName = '';
        this._lastName = '';
        this._email = '';
        this._phone = '';
        this._message = '';
    }

    get firstName() {
        return this._firstName;
    }

    setFirstName(value) {
        this._firstName = value;
    }

    get lastName() {
        return this._lastName;
    }

    setLastName(value) {
        this._lastName = value;
    }

    get email() {
        return this._email;
    }

    setEmail(value) {
        this._email = value;
    }

    get phone() {
        return this._phone;
    }

    setPhone(value) {
        this._phone = value;
    }

    get message() {
        return this._message;
    }

    setMessage(value) {
        this._message = value;
    }
}