import React, {useContext, useEffect, useState} from 'react';
import {NavLink, Redirect, useRouteMatch} from "react-router-dom";
import {MAIN_ROUTE, PAGE_SIX, PAGE_THREE} from "../../routes/consts";
import {Context} from "../../index";
import NavLinkIconBack from "../../components/NavLinkIconBack";
import {InputForm} from "../../components/Input";
import {observer} from "mobx-react-lite";
import CarrotVideoBox from "../../components/CarrotVideoBox";
import {handleNumber} from "../../utils/handleNumber";

const PageThreeOption3 = observer(() => {
    const {page} = useContext(Context);
    const {personalInformation} = useContext(Context);
    const url = '/' + page.isUrl;
    const match = useRouteMatch();
    const [email, setEmail] = useState(false);
    const [emailV, setEmailV] = useState(false);
    const [hideFormError, setHideFormError] = useState(true);
    const [redirect, setRedirect] = useState(false);
    
    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);

        if (personalInformation.email && !email && !emailV)
            emailValidation()
    }, []);
    
    if (page.amount == "" || page.cardNumber == "" || page.cardDate == "")
      return <Redirect to={match + MAIN_ROUTE} />;
    
    if(redirect)
        return <Redirect to={url + PAGE_SIX}/>;
    
    const emailValidation = () => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const result =  (!personalInformation.email || regex.test(personalInformation.email) === false) ? false : true;
        setEmail(result);
        setEmailV(!result)
    };
    
    const handleValidation = () => {
      if (
        personalInformation.firstName.length > 0 &&
        personalInformation.lastName.length > 0 &&
        personalInformation.email.length > 0 &&
        personalInformation.phone.length > 0 &&
        email
      ) {
        setHideFormError(true);
        setRedirect(true);
      } else {
        setHideFormError(false);
      }
    };

    return (
      <div className="wrapper">
        <CarrotVideoBox />
        <div className="wrapper-bg wrapper-bg-image w-100 d-flex align-items-start flex-column">
          <div
            className="container"
            style={{ padding: "100px 50px 40px 50px" }}
          >
            <NavLinkIconBack url={url + PAGE_THREE} />
            <div className="title">
              <h1>Personal information</h1>
            </div>
            <div className="w-100 d-flex justify-content-between mb-4">
              <div className="required p-relative">
                <InputForm
                  className="form required name-input"
                  placeholder="First name"
                  value={personalInformation.firstName}
                  minLength={1}
                  maxLength={50}
                  hideFormError={hideFormError}
                  onChange={(e) => {
                    personalInformation.setFirstName(e.target.value);
                  }}
                />
              </div>
              <div className="required p-relative">
                <InputForm
                  className="form required name-input"
                  placeholder="Last name"
                  value={personalInformation.lastName}
                  minLength={1}
                  maxLength={50}
                  hideFormError={hideFormError}
                  onChange={(e) => {
                    personalInformation.setLastName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="w-100 required p-relative">
              <InputForm
                className={`w-100 form mb-4 ${emailV ? "border-red" : null}`}
                placeholder="Email"
                value={personalInformation.email}
                minLength={1}
                maxLength={50}
                hideFormError={hideFormError}
                type="email"
                onChange={(e) => {
                  personalInformation.setEmail(e.target.value);
                  emailValidation();
                }}
              />
            </div>
            <div className="w-100 required p-relative">
              <InputForm
                className="w-100 form required mb-4"
                type="tel"
                placeholder="Phone number"
                value={personalInformation.phone}
                minLength={1}
                maxLength={50}
                hideFormError={hideFormError}
                onChange={(e) => {
                  personalInformation.setPhone(handleNumber(e));
                }}
              />
            </div>
            <div className="title my-2">
              <h1 className="mb-2">Additional Information</h1>
            </div>
            <InputForm
              className="w-100 form mb-4"
              placeholder="Message"
              value={personalInformation.message}
              maxLength={100}
              onChange={(e) => {
                personalInformation.setMessage(e.target.value);
              }}
            />
            <div className="w-100 text-center">
              <div
                style={
                  personalInformation.firstName.length > 0 &&
                  personalInformation.lastName.length > 0 &&
                  personalInformation.email.length > 0 &&
                  personalInformation.phone.length > 0 &&
                  email
                    ? null
                    : { opacity: "0.7" }
                }
                className={`btn btn-secondary w-75`}
                onClick={handleValidation}
              >
                Go
              </div>
            </div>
          </div>
        </div>
      </div>
    );
});

export default PageThreeOption3;