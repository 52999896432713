import React, {useContext, useState} from 'react';
import VideoBox from "../../components/VideoBox";
import {NavLink, Redirect, useRouteMatch} from "react-router-dom";
import {
  MAIN_ROUTE,
  PAGE_DONE,
  PAGE_ERROR,
  PAGE_FIVE,
} from "../../routes/consts";
import { Context } from "../../index";
import { sendDonation } from "../../http/sendAPI";
import { observer } from "mobx-react-lite";
import Loader from "./images/data-loader.svg";

const PageSix = observer(() => {
  const { personalInformation, page } = useContext(Context);
  const match = useRouteMatch();
  const url = "/" + page.isUrl;
  const [redirect, setRedirect] = useState(false);
  const [loading, setloading] = useState(false);

  if (
    personalInformation.firstName == "" ||
    personalInformation.lastName == "" ||
    personalInformation.email == "" ||
    personalInformation.phone == "" ||
    page.amount == "" ||
    page.cardNumber == "" ||
    page.cardDate == ""
  )
    return <Redirect to={match + MAIN_ROUTE} />;

  const sendRequest = async () => {
    const data = {
      amount: page.amount,
      donor: {
        firstname: personalInformation.firstName,
        lastname: personalInformation.lastName,
        email: personalInformation.email,
        phone: personalInformation.phone,
        description: personalInformation.message,
      },
      card: {
        number: page.cardNumber.replace(/\s+/g, ""),
        date: page.cardDate.replace(/[/\s]+/g, ""),
        cvv: page.cardCvc,
      },
      payment_type: "Credit Card",
      kiosk_id: page.kioskId,
      currency_id: page.currencyId,
      payment_id: page.paymentId,
      donation_type: page.donate ? "subscription" : "donate",
      group_id: page.groupId,
      subscription_duration_id: page.subscriptionDurationId,
    };
    setloading(true);
    await sendDonation(data)
      .then(() => setRedirect(PAGE_DONE))
      .catch((e) => {
        console.log(JSON.stringify({ error: e }, null, 2))
        setRedirect(PAGE_ERROR);
      });
  };

  if (redirect) return <Redirect to={url + redirect} />;

  return (
    <div className="wrapper-2">
      {loading === true && (
        <div class="loading">
          <div>
            <img src={Loader} alt="Loading.." />
          </div>
        </div>
      )}
      <VideoBox />
      <div
        className="container-box d-flex align-items-start flex-column"
        style={{
          justifyContent: "space-around",
        }}
      >
        <NavLink to={url + PAGE_FIVE}>
          <div className="icon-back-blue"></div>
        </NavLink>
        <div className="title my-2">
          <h1 className="mb-2">Check if everything is right</h1>
        </div>
        <div className="w-100 d-flex mb-4">
          <div className="w-50 final-box word-wrap">
            <label>First name</label>
            <p className="name-input">{personalInformation.firstName}</p>
          </div>
          <div className="w-50 final-box word-wrap">
            <label>Last name</label>
            <p className="name-input">{personalInformation.lastName}</p>
          </div>
        </div>
        <div className="w-100 final-box mb-4 word-wrap">
          <label>Email</label>
          <p>{personalInformation.email}</p>
        </div>
        <div className="w-100 final-box mb-4 word-wrap">
          <label>Phone number</label>
          <p>{personalInformation.phone}</p>
        </div>
        <div className="w-100 final-box mb-4 word-wrap">
          <label>Message</label>
          <p>{personalInformation.message}</p>
        </div>
        <div className="w-100 final-box mb-4 word-wrap">
          <label>Donation amount</label>
          <p>
            {page.currencySymbol} {page.amount}
          </p>
        </div>
        <div className="w-100">
          <div
            style={{ boxSizing: "border-box" }}
            className="btn big btn-primary w-100"
            onClick={() => sendRequest()}
          >
            Donate
          </div>
        </div>
      </div>
    </div>
  );
});

export default PageSix;