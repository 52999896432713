import React, {useContext, useState} from 'react';
import {Context} from "../../index";
import {MAIN_ROUTE, PAGE_DONE, PAGE_ERROR, PAGE_FIVE, PAGE_ONE} from "../../routes/consts";
import {NavLink, Redirect, useRouteMatch} from "react-router-dom";
import { sendDonation } from "../../http/sendAPI";
import NavLinkIconBack from "../../components/NavLinkIconBack";
import { observer } from "mobx-react-lite";
import CarrotVideoBox from "../../components/CarrotVideoBox";
import Loader from "./images/data-loader.svg";

const PageFourOption3 = observer(() => {
  const { personalInformation } = useContext(Context);
  const { page } = useContext(Context);
  const [redirect, setRedirect] = useState(null);
  const url = "/" + page.isUrl;
  const match = useRouteMatch();
  const [loading, setloading] = useState(false);

  if (
    personalInformation.firstName == "" ||
    personalInformation.lastName == "" ||
    personalInformation.email == "" ||
    personalInformation.phone == "" ||
    page.amount == "" ||
    page.cardNumber == "" ||
    page.cardDate == ""
  )
    return <Redirect to={match + MAIN_ROUTE} />;

  const sendRequest = async () => {
    const data = {
      amount: page.amount,
      donor: {
        firstname: personalInformation.firstName,
        lastname: personalInformation.lastName,
        email: personalInformation.email,
        phone: personalInformation.phone,
        description: personalInformation.message,
      },
      card: {
        number: page.cardNumber.replace(/\s+/g, ""),
        date: page.cardDate.replace(/[/\s]+/g, ""),
        cvv: page.cardCvc,
      },
      payment_type: "Credit Card",
      kiosk_id: page.kioskId,
      currency_id: page.currencyId,
      donation_type: page.donate ? "subscription" : "donate",
    };
    setloading(true);
    await sendDonation(data)
      .then(() => setRedirect(PAGE_DONE))
      .catch((e) => {
        console.log(JSON.stringify({ error: e }, null, 2));
        console.log("donation error occurred");
        setRedirect(PAGE_ERROR);
      });
  };

  if (redirect) return <Redirect to={url + redirect} />;

  return (
    <div className="wrapper">
      {loading === true && (
        <div class="loading">
          <div>
            <img src={Loader} alt="Loading.." />
          </div>
        </div>
      )}
      <CarrotVideoBox />
      <div className="wrapper-bg wrapper-bg-image w-100 d-flex align-items-start flex-column">
        <div className="container" style={{ padding: "100px 50px 40px 50px" }}>
          <NavLinkIconBack url={url + PAGE_FIVE} />
          <div className="title">
            <h1>Check if everything is right</h1>
          </div>
          <div className="w-100 d-flex mb-4">
            <div className="w-50 final-box word-wrap">
              <label>First name</label>
              <p className="name-input">{personalInformation.firstName}</p>
            </div>
            <div className="w-50 final-box word-wrap">
              <label>Last name</label>
              <p className="name-input">{personalInformation.lastName}</p>
            </div>
          </div>
          <div className="w-100 final-box mb-4 word-wrap">
            <label>Email</label>
            <p>{personalInformation.email}</p>
          </div>
          <div className="w-100 final-box mb-4 word-wrap">
            <label>Phone number</label>
            <p>{personalInformation.phone}</p>
          </div>
          <div className="w-100 final-box mb-4 word-wrap">
            <label>Message</label>
            <p style={{ fontSize: "400" }}>{personalInformation.message}</p>
          </div>
          <div className="w-100 final-box mb-4 word-wrap">
            <label>Donation amount</label>
            <p>
              {page.currencySymbol} {page.amount}
            </p>
          </div>
          <div className="w-100 text-center">
            <div
              className={`btn btn-secondary w-75`}
              onClick={() => sendRequest()}
            >
              Next
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PageFourOption3;