import React, { useContext, useEffect, useState } from "react";
import VideoBox from "../../components/VideoBox";
import "./style.css";
import { NavLink, Redirect, useHistory, useRouteMatch } from "react-router-dom";
import {
  MAIN_ROUTE,
  PAGE_FIVE,
  PAGE_ONE,
  PAGE_THREE,
  PAGE_TWO,
} from "../../routes/consts";
import Card from "./images/icon-card.png";
import Arrow from "./images/icon-arrow.png";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import PageOneOption3 from "../Option3/page_one";
import MagStripeInput from "../../components/MagStripeInput";

const PagePaymentGateway = observer(() => {
  const { page, handleCardRead, handleCardReadError, handleCardExpiry } =
    useContext(Context);
  const match = useRouteMatch();
  const url = "/" + page.isUrl;
  const [goNext, setGoNext] = useState(false);

  const history = useHistory();

  if (page.amount == "") return <Redirect to={match + MAIN_ROUTE} />;

  if (goNext) return <Redirect to={url + PAGE_FIVE} />;

  const selectedCurrecyPaymentGateways = page.availableCurrencies.filter(
    (item, index) => {
      return item.currency_id == page.currencyId;
    }
  );

  const handlePaymentGatewayChange = (item) => {
    page.setPaymentId(item.id);
    page.setPaymentName(item.name);

    console.log(page.paymentId)

    history.push(url + PAGE_TWO);
  };

  return (
    <div className="wrapper-2 swipe-card">
      <MagStripeInput
        onCardExpired={handleCardExpiry}
        onSubmit={(card) => handleCardRead(card, () => setGoNext(true))}
        onInvalidData={handleCardReadError}
      />
      <VideoBox />
      <div
        className="container-box d-flex align-items-start justify-content-center flex-column"
        style={{ background: "#182F4B" }}
      >
        <NavLink to={url + PAGE_ONE}>
          <div className="icon-back"></div>
        </NavLink>
        <h1 style={{ width: "100%" }}> Select the Payement Gateway</h1>
        <p>If something does not work, please notify a team member.</p>

        <div className="d-flex align-items-center" style={{ marginTop: 32 }}>
          {selectedCurrecyPaymentGateways[0].available_payments.map(
            (item, index) => {
              console.log(item);
              return (
                <div
                  key={index}
                  className={`btn blue-text big btn-primary`}
                  style={{ marginRight: 15 }}
                  onClick={() => handlePaymentGatewayChange(item)}
                >
                  {item.name}
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
});

export default PagePaymentGateway;
