import React, { useContext, useEffect } from "react";
import { Route, Switch, useParams, Redirect } from "react-router-dom";
import { Context } from "../index";
import {
  CURRENCY_PAGE,
  MAIN_ROUTE,
  PAGE_DONE,
  PAGE_ERROR,
  PAGE_FIVE,
  PAGE_ONE,
  PAGE_PAYMENT_GATEWAY,
  PAGE_SIX,
  PAGE_THREE,
  PAGE_TWO,
} from "./consts";
import PageMain from "../view/Option2/page_main";
import PageMainOption3 from "../view/Option3/page_main";
import PageOneOption3 from "../view/Option3/page_one";
import PageTwoOption3 from "../view/Option3/page-two";
import PageThreeOption3 from "../view/Option3/page-three";
import PageFourOption3 from "../view/Option3/page-four";
import DoneOption3 from "../view/Option3/done";
import { getKioskDataBySlug } from "../http/sendAPI";
import { observer } from "mobx-react-lite";
import PageOne from "../view/Option2/page_one";
import PageTwo from "../view/Option2/page-two";
import PageThree from "../view/Option2/page-three";
import PageFive from "../view/Option2/page-five";
import PageSix from "../view/Option2/page_six";
import DoneOption2 from "../view/Option2/done";
import PageErrorOption3 from "../view/Option3/page-error";
import PageErrorOption2 from "../view/Option2/page-error";
import CurrencyPage from "../view/Option2/currency_page";
import PagePaymentGateway from "../view/Option2/page_payment_gateway";
import TestPage from "../view/TestPage";

const SlugRoute = observer(() => {
  let { url } = useParams();
  const { page } = useContext(Context);

  useEffect(() => {
	if (url == "testkeyboard") {
    }else{
    getKioskDataBySlug(url).then((request) => {
      console.log("Request", request);
      page.setKioskId(request.data.data.id);
      page.setIsPage(request.data.data.template.name);
      page.setAvailableCurrencies(request.data.data.available_currency);
      // page.setCurrencyId(request.data.data.currency.id);
      // page.setCurrencySymbol(request.data.data.currency.symbol);
      page.setDonationOptions(request.data.data.donation_options);
      page.setImageLogo(request.data.data.logo);
      page.setImageBackground(request.data.data.background);
      page.setIsUrl(url);
      page.setGroups(request.data.data.groups);
      if (request.data.data.template.name == "carrot")
        document.body.classList.add("wrapper-bg-image");
      document.title = `Kiosk - ${request.data.data.title}`;
    });
    }
  }, [url]);
  
  if (url == "testkeyboard") {
    return <TestPage />;
  }

  if (page.isPage) {
    switch (page.isPage) {
      case "blueberry":
        return (
          <Switch>
            <Route
              path={`/${url + MAIN_ROUTE}`}
              children={<PageMain />}
              exact
            />
            {/* <Route path={`/${url + CURRENCY_PAGE}`} children={<CurrencyPage />} exact /> */}
            <Route path={`/${url + PAGE_PAYMENT_GATEWAY}`} children={<PagePaymentGateway />} exact />
            <Route path={`/${url + PAGE_ONE}`} children={<PageOne />} exact />
            <Route path={`/${url + PAGE_TWO}`} children={<PageTwo />} exact />
            <Route
              path={`/${url + PAGE_THREE}`}
              children={<PageThree />}
              exact
            />
            <Route path={`/${url + PAGE_FIVE}`} children={<PageFive />} exact />
            <Route path={`/${url + PAGE_SIX}`} children={<PageSix />} exact />
            <Route
              path={`/${url + PAGE_DONE}`}
              children={<DoneOption2 />}
              exact
            />
            <Route
              path={`/${url + PAGE_ERROR}`}
              children={<PageErrorOption2 />}
              exact
            />

            <Redirect to={"/" + url + MAIN_ROUTE} />
          </Switch>
        );
      case "carrot":
        return (
          <Switch>
            <Route
              path={`/${url + MAIN_ROUTE}`}
              children={<PageMainOption3 />}
              exact
            />
            <Route
              path={`/${url + PAGE_TWO}`}
              children={<PageOneOption3 />}
              exact
            />
            <Route
              path={`/${url + PAGE_THREE}`}
              children={<PageTwoOption3 />}
              exact
            />
            <Route
              path={`/${url + PAGE_FIVE}`}
              children={<PageThreeOption3 />}
              exact
            />
            <Route
              path={`/${url + PAGE_SIX}`}
              children={<PageFourOption3 />}
              exact
            />
            <Route
              path={`/${url + PAGE_DONE}`}
              children={<DoneOption3 />}
              exact
            />
            <Route
              path={`/${url + PAGE_ERROR}`}
              children={<PageErrorOption3 />}
              exact
            />

            <Redirect to={"/" + url + MAIN_ROUTE} />
          </Switch>
        );
      default:
    }
  } else {
    return (
      <div
        style={{
          minHeight: "100vh",
        }}
      >
        <h1
          style={{
            margin: 0,
            position: "absolute",
            top: "50%",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          Loading
        </h1>
      </div>
    );
  }
});

export default SlugRoute;
