import React, {useContext, useState} from 'react';
import {Context} from "../../index";
import {MAIN_ROUTE, PAGE_SIX} from "../../routes/consts";
import {NavLink, Redirect, useRouteMatch} from "react-router-dom";
import {observer} from "mobx-react-lite";
import BackgroundOption3 from "../../components/BackgroundOption3";

const DoneOption3 = observer(() => {
    const {personalInformation} = useContext(Context);
    const {page} = useContext(Context);
    const url = '/' + page.isUrl;
    const [redirect, setRedirect] = useState(false);

    page.setClear();
    personalInformation.setClear();
    setTimeout(()=> setRedirect(true), 15000);
    
    if (redirect)
        return <Redirect to={url + MAIN_ROUTE}/>
    
    return (
        <div className='wrapper'>
            <BackgroundOption3>
                <div className="container" style={{padding: '100px 50px 40px 50px'}}>
                    <div className="title">
                        <h1>Thank you!</h1>
                    </div>
                    <p className='text-center my-5' style={{fontSize: '21px', fontWeight: '400'}}>We are very grateful
                        for your donation.<br/>Have a wonderful day :)</p>
                    <div className='w-100 text-center'>
                        <NavLink
                            className={`btn btn-secondary w-75`}
                            to={url + MAIN_ROUTE}
                        >Next</NavLink>
                    </div>
                </div>
            </BackgroundOption3>
        </div>
    )
});

export default DoneOption3;