import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import PageStore from "./store/PageStore";
import PersonalInformationStore from "./store/PersonalInformationStore";


export const Context = createContext(null);

const page = new PageStore();
const personalInformation = new PersonalInformationStore();

const handleCardRead = (data, cb) => {
  page.setCardNumber(data.card_number);
  page.setCardDate(data.expiration_date);
  personalInformation.setFirstName(data.first_name);
  personalInformation.setLastName(data.last_name);
  if (cb && typeof cb === "function") {
    cb();
  }
};

const handleCardExpiry = (e) => {
  alert("Card expiry date is not valid");
};

const handleCardReadError = (e) => {
  alert("Card read error. Try again or use a different card!");
};

ReactDOM.render(
  <Context.Provider
    value={{
      page,
      personalInformation,
      handleCardRead,
      handleCardExpiry,
      handleCardReadError,
    }}
  >
    <App />
  </Context.Provider>,
  document.getElementById("root")
);

