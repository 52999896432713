import React, { useContext, useState } from "react";
import VideoBox from "../../components/VideoBox";
import "./style.css";
import { Context } from "../../index";
import { NavLink } from "react-router-dom";
import { CURRENCY_PAGE, PAGE_ONE } from "../../routes/consts";
import { observer } from "mobx-react-lite";

const PageMain = observer(() => {
  const { page } = useContext(Context);
  
  const handleGroupChange = (e) => {
    console.log(e.target.value);
    page.setGroupId(e.target.value)
  };

  const handleSubscriptionDurationChange = (e) => {
    page.setSubscriptionDurationId(e.target.value);
  };

  return (
    <div className="wrapper-2">
      <VideoBox />
      <div className="container-box">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            flex: 1,
          }}
        >
          <div className="donate-chose d-flex align-items-start flex-column">
            <div>
              <h1>How do you want to donate?</h1>
              <div
                className="d-flex justify-content-center w-100"
                style={{ marginTop: "36px" }}
              >
                <div
                  className={`btn blue-text big mr-3 ${
                    !page.donate ? "btn-primary" : ""
                  }`}
                  onClick={() => page.setDonate(false)}
                >
                  Once
                </div>
                <div
                  className={`btn blue-text big ${
                    page.donate ? "btn-primary" : ""
                  }`}
                  onClick={() => page.setDonate(true)}
                >
                  Monthly *
                </div>
              </div>

              {page?.groups?.length ? (
                <>
                  <h1 style={{ marginTop: 32 }}>
                    What group do you belong to?
                  </h1>
                  <div className="currency_select" style={{ marginBottom: 0 }}>
                    <select
                      name="currecy_list"
                      onChange={(e) => handleGroupChange(e)}
                    >
                      <option value="">Select</option>
                      {page.groups?.map((item, index) => {
                        return (
                          <option value={item?.campaign_id} key={index}>
                            {item?.campaign_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </>
              ) : null}

              {page.donate == true ? (
                <>
                  <h1 style={{ marginTop: 110 }}>
                    How long the subscription should go for?
                  </h1>
                  <div className="currency_select" style={{ marginBottom: 0 }}>
                    <select
                      name="subscription_duration_list"
                      onChange={(e) => handleSubscriptionDurationChange(e)}
                    >
                      <option value={1}>Ongoing</option>
                      <option value={2}>6 months</option>
                      <option value={3}>1 year</option>
                      <option value={4}>3 years</option>
                    </select>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{ marginTop: "5rem" }}
          >
            <div
              className="p-relative"
              style={{ margin: "0 20px 0 20px" }}
            ></div>
            <NavLink
              className="btn btn-primary big btn-go"
              to={"/" + page.isUrl + PAGE_ONE}
            >
              Go
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PageMain;
