import React, { useContext, useEffect, useRef, useState } from "react";
import VideoBox from "../components/VideoBox";
import { NavLink, Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { MAIN_ROUTE, PAGE_FIVE, PAGE_TWO } from "../routes/consts";
import CardIcon from "./Option2/images/icon-card-small.png";
import { Context } from "../index";
import { observer } from "mobx-react-lite";
import { usePaymentInputs } from "react-payment-inputs";
import VirtualKeyboard from "react-simple-keyboard";

const TestPage = observer(() => {
  const { page } = useContext(Context);
  const match = useRouteMatch();
  const url = "/" + page.isUrl;
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();
  const [dateError, setDateError] = useState(false);
  const [cardData, setCardData] = useState({
    cardNumber: "",
    cardDate: "",
    cardCvv: "",
  });
  const [dateErrorCardNumber, setDateErrorCardNumber] = useState(false);
  localStorage.setItem("card", JSON.stringify(cardData));

  const [timer, setTimer] = useState(30);
  const history = useHistory();

  const [activeInput, setActiveInput] = useState("");

  const isDateGreaterThanToday = (date) => {
    date = date.replace(/\s/g, "");
    let dS = date.split("/");
    let d1 = new Date("20" + dS[1], +dS[0] - 1);
    let today = new Date();
    setDateError(!(d1 > today));
  };

  const formatCardNumber = (value) => {
    var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    var matches = v.match(/\d{4,16}/g);
    var match = (matches && matches[0]) || "";
    var parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join("");
    } else {
      return value;
    }
  };

  const formatCardExpiry = (text) => {
    // Strip non-numeric characters from input
    const cleanText = text.replace(/[^0-9]/g, "");

    // Format input as mm/yy
    let formattedText = "";
    for (let i = 0; i < cleanText.length; i++) {
      if (i === 2) {
        formattedText += "/";
      }
      formattedText += cleanText[i];
      if (i === 3 && cleanText.length > 4) {
        formattedText += "/";
      }
    }

    return formattedText;

    // const formattedValue = text.replace(/\D/g, "").substring(0, 4);
    // const month = formattedValue.substring(0, 2);
    // const year = formattedValue.substring(2, 4);

    // return `${month}/${year}`;
  };

  const handleChangeCardNumber = (value) => {
    let data = formatCardNumber(value);
    setDateErrorCardNumber(data.length !== 19);
    setCardData({
      ...cardData,
      cardNumber: data,
    });
    page.setCardNumber(data);
    keyboard.current["card_number"].setInput(data);
  };

  const handleChangeExpiryDate = (value) => {
    // let data = formatCardExpiry(value);
    let numericValue = value.replace(/[^0-9\/]/g, "");

    let data = numericValue.slice(0, 5);

    if (data.length == 7) isDateGreaterThanToday(data);
    setCardData({
      ...cardData,
      cardDate: data,
    });
    page.setCardDate(data);
    keyboard.current["card_expiry"].setInput(data);
    // console.log(keyboard.current['card_expiry'].input);
  };

  const handleChangeCVC = (value) => {
    let numericValue = value.replace(/[^0-9]/g, "");

    let data = numericValue.slice(0, 3);

    setCardData({
      ...cardData,
      cardCvv: data,
    });
    page.setCardCvc(data);
    keyboard.current["card_cvv"].setInput(data);
  };

  const hideVirtualKeyboard = () => {
    setActiveInput(null);
  };

  const [layout, setLayout] = useState("default");
  const keyboard = useRef([]);

  const onChange = (text) => {
    if (activeInput == "card_number") {
      handleChangeCardNumber(text);
    } else if (activeInput == "card_expiry") {
      handleChangeExpiryDate(text);
    } else if (activeInput == "card_cvv") {
      handleChangeCVC(text);
    }
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  return (
    <div className="wrapper-2">
      <VideoBox />
      <div className="container-box d-flex align-items-start flex-column">
        <div className="d-flex align-items-center justify-content-between w-100">
          {/* <NavLink to={'/'}> */}
          <div className="icon-back-blue"></div>
          {/* </NavLink> */}
          <div className="" style={{ marginLeft: "auto" }}>
            <div
              className={`btn blue-text big btn-primary`}
              style={{ marginRight: 15 }}
              //   onClick={() => {
              //     page.setClear();
              //     history.push(url + MAIN_ROUTE)
              //   }}
            >
              Reset
            </div>
            <NavLink
              style={
                page.cardNumber.length >= 16 &&
                page.cardDate.length >= 4 &&
                page.cardCvc.length >= 3 &&
                !(meta.isTouched && meta.error) &&
                !dateError &&
                !dateErrorCardNumber
                  ? null
                  : { pointerEvents: "none" }
              }
              className={`btn btn-primary big f-right btn-go`}
              to={"/"}
            >
              Go
            </NavLink>
          </div>
        </div>
        <div
          className="title"
          style={{ marginTop: "2rem", marginBottom: "3rem" }}
        >
          <h1>Payment details</h1>
        </div>
        <div
          className={`card-form d-flex justify-content-between align-items-center ${
            (meta.isTouched && meta.error) || dateError || dateErrorCardNumber
              ? "border-red"
              : ""
          }`}
        >
          <img
            src={CardIcon}
            height="25"
            width="37"
            style={{ marginRight: "15px" }}
          />
          {/* {console.log(getCardNumberProps())} */}
          <input
            placeholder="Card Number"
            value={page.cardNumber}
            onChange={(e) => handleChangeCardNumber(e.target.value)}
            name="card_number"
            onFocus={() => setActiveInput("card_number")}
            onBlur={hideVirtualKeyboard}
          />
          <input
            style={{ width: "80px" }}
            placeholder="MM/YY"
            value={page.cardDate}
            maxLength={5}
            onChange={(e) => handleChangeExpiryDate(e.target.value)}
            name="card_expiry"
            onFocus={() => setActiveInput("card_expiry")}
            onBlur={hideVirtualKeyboard}
          />
          <input
            type="number"
            style={{ width: "75px" }}
            placeholder="CVV"
            value={page.cardCvc}
            onChange={(e) => handleChangeCVC(e.target.value)}
            name="card_cvv"
            onFocus={() => setActiveInput("card_cvv")}
            onBlur={hideVirtualKeyboard}
          />
        </div>
        <div className="my-4">
          {meta.isTouched && meta.error && (
            <span style={{ color: "red" }}>Error: {meta.error}</span>
          )}
          {!(meta.isTouched && meta.error) && dateError && (
            <span style={{ color: "red" }}>
              Error: Expiry date cannot be in the past
            </span>
          )}
        </div>
        <div className="card-text w-100 my-2 p-relative">
          The CVV code is located on the back of your credit/debit card on the
          right side.
        </div>
        <small className="">Page will expire in 30 seconds</small>

        {["card_number", "card_expiry", "card_cvv"].map((item, index) => {
          return activeInput == item ? (
            <div key={index} className="w-100" style={{ marginTop: 28 }}>
              <VirtualKeyboard
                keyboardRef={(r) => (keyboard.current[item] = r)}
                layoutName={layout}
                inputName={activeInput}
                onChange={onChange}
                onKeyPress={onKeyPress}
                disableButtonHold
                preventMouseDownDefault
              />
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
});

export default TestPage;
