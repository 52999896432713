import React, {useContext, useState} from 'react';
import logo from "./images/icon-logo.png";
import {NavLink, Redirect} from "react-router-dom";
import {MAIN_ROUTE, PAGE_THREE} from "../../routes/consts";
import {Context} from "../../index";

const PageErrorOption2 = () => {
  const { page } = useContext(Context);
  const url = "/" + page.isUrl;
  const [redirect, setRedirect] = useState(false);

  // setTimeout(() => setRedirect(true), 15000);

  if (redirect) return <Redirect to={url + PAGE_THREE} />;

  return (
    <div className="wrapper-2 blue-bg">
      <div className="w-100 d-flex align-items-start flex-column my-5">
        <div className="p-relative w-100 text-center">
          <img src={page.imageLogo} style={{ width: "180px" }} />
          <NavLink
            to={url + MAIN_ROUTE}
            className="icon-back icon-back-blue-crosse"
          ></NavLink>
        </div>
        <div className="w-100 text-center" style={{ margin: "auto 0" }}>
          <h1
            className="my-5"
            style={{ fontSize: "150px", marginTop: "50px", color: "#fff" }}
          >
            Oops!
          </h1>
          <p
            className="w-50 text-center"
            style={{ margin: "0 auto", fontSize: "28px", color: "#fff" }}
          >
            There was a problem processing your payment please try again with a different card.
          </p>
        </div>

        <div className="w-100 d-flex justify-content-center my-5">
          <NavLink
            className="btn big btn-light"
            style={{ fontWeight: "700" }}
            to={url + PAGE_THREE}
          >
            Try again
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default PageErrorOption2;