import React, {useContext, useEffect, useState} from 'react';
import './style.css';
import {Context} from "../../index";
import {PAGE_TWO} from "../../routes/consts";
import {NavLink} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {InputDecimal} from "../../components/Input/InputDecimal";
import CarrotVideoBox from "../../components/CarrotVideoBox";

const PageMainOption3 = observer(() => {
    const {page} = useContext(Context);

    return (
      <div className="wrapper">
        <CarrotVideoBox />
        <div className="wrapper-bg wrapper-bg-image w-100 d-flex align-items-start flex-column">
          <div className="container">
            <div className="title">
              <h1>Make a donation</h1>
            </div>
            <div className="w-100">
              <InputDecimal
                className="donate-amount"
                placeholder="Enter amount"
                type="text"
                page={page}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center my-5">
              <label className="checkbox">one time</label>
              <div
                className="checkbox"
                onClick={() => {
                  page.setDonate(!page.donate);
                }}
              >
                <div
                  className={`checkbox-ball ${page.donate ? "f-right" : ""}`}
                ></div>
              </div>
              <label className="checkbox">recurring</label>
            </div>
            <NavLink
              style={
                page.amount !== "" && parseFloat(page.amount) !== 0
                  ? null
                  : { pointerEvents: "none" }
              }
              className="btn btn-secondary btn-full"
              to={"/" + page.isUrl + PAGE_TWO}
            >
              Next
            </NavLink>
          </div>
        </div>
      </div>
    );
});

export default PageMainOption3;