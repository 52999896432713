import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../index";
import {
  MAIN_ROUTE,
  PAGE_PAYMENT_GATEWAY,
  PAGE_TWO,
} from "../../routes/consts";
import { NavLink } from "react-router-dom";
import VideoBox from "../../components/VideoBox";
import { observer } from "mobx-react-lite";
import { InputDecimal } from "../../components/Input/InputDecimal";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Modal from "react-modal";

Modal.setAppElement("div#root");

const PageOne = observer(() => {
  const { page } = useContext(Context);
  const url = "/" + page.isUrl;

  const amountInputRef = useRef();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [keyboardVisible, setKeyboardVisible] = useState(false);

  useEffect(() => {
    page.setCurrencyId(page?.availableCurrencies[0]?.currency_id);
    page.setCurrencySymbol(page?.availableCurrencies[0]?.currency?.symbol);
    page.setPaymentId(page?.availableCurrencies[0]?.payment_id);
  }, []);

  const inputChange = (e) => {
    page.setAmount(e);
  };

  const handleCurrecyChange = (e) => {
    let currency = JSON.parse(e.target.value);
    page.setCurrencyId(currency?.currency?.id);
    page.setCurrencySymbol(currency?.currency?.symbol);
    page.setPaymentId(currency?.payment_id);
  };

  const closeModal = () => setModalIsOpen(false);

  const onChange = (e) => {
    console.log("change", e);
  };

  const onKeyPress = (e) => {
    console.log("keypress", e);
    if (e == "{bksp}") {
      page.setAmount(page.amount.slice(0, -1));
      return;
    }

    if (e == "Done") {
      closeModal();
      return;
    }

    if (e == ".") {
      if (!page.amount.includes(".")) {
        page.setAmount(page.amount.toString() + ".");
      }
      return;
    }

    page.setAmount(page.amount.toString() + e.toString());
  };

  return (
    <div className="wrapper-2">
      <VideoBox />
      <div className="container-box d-flex flex-column">
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ margin: "auto 0px" }}>
            <div className="title">
              <h1>
                How much do you want to donate{" "}
                <span>{page.donate ? "every month" : ""}</span>?
              </h1>
            </div>
            <div
              className="d-flex"
              style={{
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 12,
                paddingRight: 12,
              }}
            >
              <div className="w-50" style={{ marginRight: 14 }}>
                <InputDecimal
                  className="donate-amount"
                  placeholder="Enter amount"
                  type="number"
                  amount={page.amount}
                  page={page}
                  ref={amountInputRef}
                  onClick={() => setModalIsOpen(true)}
                />
              </div>
              <div className="currency_select w-50" style={{ marginBottom: 0 }}>
                <select
                  name="currecy_list"
                  onChange={(e) => handleCurrecyChange(e)}
                >
                  {page.availableCurrencies?.map((item, index) => {
                    return (
                      <option value={JSON.stringify(item)} key={index}>
                        {item?.currency?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {page.donationOptions && page.donationOptions.length ? (
              <>
                <div className="w-100 or">
                  <hr />
                  <div>or</div>
                </div>
                <div className="amount">
                  {page.donationOptions.map((d, i) => (
                    <div key={i} className="amount-item">
                      <div className="box" onClick={() => inputChange(d)}>
                        <div className="icon">
                          <span>{page.currencySymbol}</span>
                        </div>
                        <p>{`${page.currencySymbol} ${d}`}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>

          <div
            className="w-100 p-relative"
            style={{
              marginTop: "0.75rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div className="change-donation-type-text">
              <NavLink to={url + MAIN_ROUTE}>Change donation type</NavLink>
            </div>
            <NavLink
              style={{
                pointerEvents:
                  page.amount !== "" && parseFloat(page.amount) !== 0
                    ? undefined
                    : "none",
              }}
              className={`btn btn-primary big f-right btn-go ${
                page.amount.length > 0 ? "" : "disable"
              }`}
              to={url + PAGE_TWO}
            >
              Go
            </NavLink>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={closeModal}
        style={{
          overlay: {
            zIndex: 999999,
          },
          content: {
            maxWidth: 300,
            height: "50%",
            position: "absolute",
            top: "50%",
            left: "30%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 24,
          }}
        >
          <h4>Enter Amount</h4>
          <span
            onClick={closeModal}
            style={{ fontSize: 20, cursor: "pointer" }}
          >
            X
          </span>
        </div>
        <div style={{}}>
          <InputDecimal
            className="donate-amount"
            placeholder="Enter amount"
            type="number"
            amount={page.amount}
            page={page}
            ref={amountInputRef}
            onClick={() => setModalIsOpen(true)}
            onKeyDown={(e) => e.preventDefault()}
          />
        </div>
        <div style={{ zIndex: 9999999 }}>
          <Keyboard
            disableButtonHold
            onKeyPress={onKeyPress}
            layout={{
              default: ["1 2 3", "4 5 6", "7 8 9", ". 0 {bksp}", "Done"],
              shift: ["1 2 3", "4 5 6", "7 8 9", ". 0 {bksp}", "Done"],
            }}
          />
        </div>
      </Modal>
    </div>
  );
});

export default PageOne;
