import React, { useContext, useEffect, useRef, useState } from "react";
import VideoBox from "../../components/VideoBox";
import { NavLink, Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { MAIN_ROUTE, PAGE_FIVE, PAGE_TWO } from "../../routes/consts";
import CardIcon from "./images/icon-card-small.png";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { usePaymentInputs } from "react-payment-inputs";
import VirtualKeyboard from "react-simple-keyboard";

const PageThree = observer(() => {
  const { page } = useContext(Context);
  const match = useRouteMatch();
  const url = "/" + page.isUrl;
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();
  const [dateError, setDateError] = useState(false);
  const [cardData, setCardData] = useState({
    cardNumber: "",
    cardDate: "",
    cardCvv: "",
  });
  const [dateErrorCardNumber, setDateErrorCardNumber] = useState(false);
  localStorage.setItem("card", JSON.stringify(cardData));

  if (page.amount == "") return <Redirect to={match + MAIN_ROUTE} />;

  const [timer, setTimer] = useState(60);
  const history = useHistory();

  const keyboard = useRef();
  const [inputs, setInputs] = useState({});
  const [layoutName, setLayoutName] = useState("default");
  const [inputName, setInputName] = useState("default");

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((t) => t - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (timer == 0) {
      page.setClear();
      history.push(url + MAIN_ROUTE);
    }
  }, [timer]);

  useEffect(() => {
    if (Object.keys(inputs).length) {
      setCardData({
        ...cardData,
        cardNumber: inputs?.cardNumber,
        cardDate: inputs?.cardDate,
        cardCvv: inputs?.cardCvc,
      });

      page.setCardNumber(inputs?.cardNumber);
      page.setCardDate(inputs?.cardDate);
      page.setCardCvc(inputs?.cardCvc);
    }
  }, [inputs]);

  useEffect(() => {
    // setInputs((prev) => ({
    //   ...prev,
    //   cardNumber: page.cardNumber,
    //   cardDate: page.cardDate,
    //   cardCvc: page.cardCvc,
    // }));
  }, []);

  useEffect(() => {
    if (inputName && keyboard) {
      // keyboard?.current?.setInput(page[inputName] || "");
    }
  }, [inputName]);

  const isDateGreaterThanToday = (date) => {
    date = date.replace(/\s/g, "");
    let dS = date.split("/");
    let d1 = new Date("20" + dS[1], +dS[0] - 1);
    let today = new Date();
    setDateError(!(d1 > today));
  };

  const formatCardNumber = (value) => {
    var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    // return v;

    var matches = v.match(/\d{4,16}/g);
    var match = (matches && matches[0]) || "";
    var parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join("");
    } else {
      return v;
    }
  };

  const handleChangeCardNumber = (value) => {
    if (!value) return "";

    let data = formatCardNumber(value);
    setDateErrorCardNumber(data.length !== 16);
    return data;
  };

  const handleChangeExpiryDate = (value) => {
    if (!value) return "";

    // Remove any non-numeric characters from the input
    const numericValue = value.replace(/\D/g, "");

    // Format the numeric value as "mm/yy"
    let formattedValue = "";
    if (numericValue.length >= 2) {
      formattedValue += numericValue.slice(0, 2) + "/";
    } else {
      formattedValue += numericValue;
    }
    if (numericValue.length > 2) {
      formattedValue += numericValue.slice(2, 4);
    }

    let data = formattedValue;

    if (data.length == 7) isDateGreaterThanToday(data);

    return data.slice(0, 5);
  };

  const handleChangeCVC = (value) => {
    if (!value) return "";

    let numericValue = value.replace(/[^0-9]/g, "");

    let data = numericValue.slice(0, 4);

    return data;
  };

  /////// input fns

  const handleInputFocus = (type) => {
    setInputName(type);
  };

  const handleInputBlur = () => {
    setInputName("");
    keyboard.current.replaceInput({ default: "", ...inputs });
  };

  const onChangeAll = (inputs) => {
    let value = inputs[inputName] || "";
    let newVal = "";

    if (inputName == "cardNumber") newVal = handleChangeCardNumber(value);
    if (inputName == "cardDate") newVal = handleChangeExpiryDate(value);
    if (inputName == "cardCvc") {
      newVal = handleChangeCVC(value);
      setCardData((prev) => ({ ...prev, cardCvv: newVal }));
      setInputs((prev) => ({ ...prev, [inputName]: newVal }));
      return;
    }

    setCardData((prev) => ({ ...prev, [inputName]: newVal }));
    setInputs((prev) => ({ ...prev, [inputName]: newVal }));
    // keyboard.current.setInput(newVal);
  };

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default";
    setLayoutName(newLayoutName);
  };

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const onChangeInput = (event) => {
    let val = event.target.value || "";

    if (inputName == "cardNumber") {
      val = handleChangeCardNumber(val);
      setCardData((prev) => ({ ...prev, [inputName]: val }));
    } else if (inputName == "cardDate") {
      val = handleChangeExpiryDate(val);
      setCardData((prev) => ({ ...prev, [inputName]: val }));
    } else if (inputName == "cardCvc") {
      val = handleChangeCVC(val);
      setCardData((prev) => ({ ...prev, cardCvv: val }));
    }

    setInputs((prev) => ({ ...prev, [inputName]: val }));
    keyboard.current.setInput(val);
  };

  return (
    <div className="wrapper-2">
      <VideoBox />
      <div className="container-box d-flex align-items-start flex-column">
        <div className="d-flex align-items-center justify-content-between w-100">
          <NavLink to={PAGE_TWO}>
            <div className="icon-back-blue"></div>
          </NavLink>
          <div className="" style={{ marginLeft: "auto" }}>
            <div
              className={`btn blue-text big btn-primary`}
              style={{ marginRight: 15 }}
              onClick={() => {
                page.setClear();
                history.push(url + MAIN_ROUTE);
              }}
            >
              Reset
            </div>
            <NavLink
              style={
                page?.cardNumber?.length >= 16 &&
                page?.cardDate?.length >= 4 &&
                page?.cardCvc?.length >= 3 &&
                !(meta.isTouched && meta.error) &&
                !dateError &&
                !dateErrorCardNumber
                  ? null
                  : { pointerEvents: "none" }
              }
              className={`btn btn-primary big f-right btn-go`}
              to={url + PAGE_FIVE}
            >
              Go
            </NavLink>
          </div>
        </div>
        <div
          className="title"
          style={{ marginTop: "2rem", marginBottom: "3rem" }}
        >
          <h1>Payment details</h1>
        </div>
        <div
          className={`card-form d-flex justify-content-between align-items-center ${
            (meta.isTouched && meta.error) || dateError || dateErrorCardNumber
              ? "border-red"
              : ""
          }`}
        >
          <img
            src={CardIcon}
            height="25"
            width="37"
            style={{ marginRight: "15px" }}
          />
          {/* <input
            type="number"
            {...getCardNumberProps({ onChange: handleChangeCardNumber })}
            value={page.cardNumber}
          />
          <input
            type="number"
            style={{ width: "75px" }}
            {...getExpiryDateProps({ onChange: handleChangeExpiryDate })}
            value={page.cardDate}
          />
          <input
            type="number"
            style={{ width: "75px" }}
            {...getCVCProps({ onChange: handleChangeCVC })}
            value={page.cardCvc}
          /> */}
          <input
            placeholder="Card Number"
            value={page.cardNumber || ""}
            onChange={onChangeInput}
            onFocus={() => handleInputFocus("cardNumber")}
            onBlur={handleInputBlur}
          />
          <input
            style={{ width: "80px" }}
            placeholder="MM/YY"
            value={page.cardDate || ""}
            maxLength={5}
            onChange={onChangeInput}
            onFocus={() => handleInputFocus("cardDate")}
            onBlur={handleInputBlur}
          />
          <input
            type="number"
            style={{ width: "75px" }}
            placeholder="CVV"
            value={page.cardCvc || ""}
            onChange={onChangeInput}
            onFocus={() => handleInputFocus("cardCvc")}
            onBlur={handleInputBlur}
          />
        </div>
        <div className="mb-2">
          {meta.isTouched && meta.error && (
            <span style={{ color: "red" }}>Error: {meta.error}</span>
          )}
          {!(meta.isTouched && meta.error) && dateError && (
            <span style={{ color: "red" }}>
              Error: Expiry date cannot be in the past
            </span>
          )}
        </div>
        <div className="card-text w-100 my-2 p-relative" style={{lineHeight: 1.2, fontSize: 14}}>
          The CVV code is located on the back of your credit/debit card on the
          right side.
        </div>
        <small className="">Page will expire in {timer} seconds</small>

        <div
          className="w-100 keyboard_div_card"
          style={{marginTop: 24}}
        >
          <VirtualKeyboard
            keyboardRef={(r) => (keyboard.current = r)}
            layoutName={layoutName}
            inputName={inputName}
            onChangeAll={onChangeAll}
            onKeyPress={onKeyPress}
            layout={{
              default: [
                "1 2 3",
                "4 5 6",
                "7 8 9",
                " 0 {bksp}",
              ],
              shift: [
                "1 2 3",
                "4 5 6",
                "7 8 9",
                " 0 {bksp}",
              ],
            }}
            display={{
              '{bksp}': '⌫'
            }}
            disableButtonHold
            preventMouseDownDefault
            preventMouseUpDefault
            stopMouseDownPropagation
            stopMouseUpPropagation
            // useTouchEvents
            useMouseEvents
          />
        </div>
      </div>
    </div>
  );
});

export default PageThree;
