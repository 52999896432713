import React, { useContext, useEffect, useRef, useState } from "react";
import VideoBox from "../../components/VideoBox";
import { NavLink, Redirect, useRouteMatch } from "react-router-dom";
import { MAIN_ROUTE, PAGE_SIX, PAGE_THREE } from "../../routes/consts";
import { Context } from "../../index";
import { InputForm } from "../../components/Input";
import { observer } from "mobx-react-lite";
import { handleNumber } from "../../utils/handleNumber";
import VirtualKeyboard from "react-simple-keyboard";

const PageFive = observer(() => {
  const { page, personalInformation } = useContext(Context);
  const match = useRouteMatch();
  const url = "/" + page.isUrl;
  const [email, setEmail] = useState(false);
  const [emailV, setEmailV] = useState(false);
  const [hideFormError, setHideFormError] = useState(true);
  const [redirect, setRedirect] = useState(false);

  const keyboard = useRef();
  const [inputs, setInputs] = useState({});
  const [layoutName, setLayoutName] = useState("default");
  const [inputName, setInputName] = useState("default");
  const [firstRunCompleted, setFirstRunCompleted] = useState(true);

  useEffect(() => {
    if (Object.keys(inputs).length) {
      personalInformation?.setFirstName(inputs?.firstName || "");
      personalInformation?.setLastName(inputs?.lastName || "");
      personalInformation?.setEmail(inputs?.email || "");
      personalInformation?.setPhone(inputs?.phone || "");
      personalInformation?.setMessage(inputs?.message || "");
    }
  }, [inputs]);

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);

    setInputs({
      ...inputs,
      firstName: personalInformation.firstName,
      lastName: personalInformation.lastName,
    });

    if (personalInformation.email && !email && !emailV) emailValidation();
  }, []);

  useEffect(() => {
    if (inputName && keyboard) {
      keyboard?.current?.setInput(personalInformation[inputName] || "");
    }
  }, [inputName]);

  if (page.amount == "" || page.cardNumber == "" || page.cardDate == "")
    return <Redirect to={match + MAIN_ROUTE} />;

  if (redirect) return <Redirect to={url + PAGE_SIX} />;

  const handleValidation = () => {
    if (
      personalInformation.firstName.length > 0 &&
      personalInformation.lastName.length > 0 &&
      personalInformation.email.length > 0 &&
      personalInformation.phone.length > 0 &&
      email
    ) {
      setHideFormError(true);
      setRedirect(true);
    } else {
      setHideFormError(false);
    }
  };

  const emailValidation = () => {
    const regex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    const result =
      !personalInformation.email ||
      regex.test(personalInformation.email) === false
        ? false
        : true;
    setEmail(result);
    setEmailV(!result);
  };

  const handleInputFocus = (type) => {
    setInputName(type);
  };

  const handleInputBlur = () => {
    setInputName("");
  };

  const onChangeAll = (inputs) => {
    let value = inputs[inputName] || "";
    if (inputName == "phone") value = value?.replace(/\D/, "") || "";
    setInputs((prev) => ({ ...prev, [inputName]: value }));
    if (inputName == "email") emailValidation();

    // if (inputName == "firstName") {
    //   setInputs((prev) => ({ ...prev, firstName: inputs?.firstName || "" }));
    // } else if (inputName == "lastName") {
    //   setInputs((prev) => ({ ...prev, lastName: inputs?.lastName || "" }));
    // } else if (inputName == "email") {
    //   setInputs((prev) => ({ ...prev, email: inputs?.email || "" }));
    //   emailValidation();
    // } else if (inputName == "phone") {
    //   setInputs((prev) => ({
    //     ...prev,
    //     phone: inputs?.phone?.replace(/\D/, "") || "",
    //   }));
    // } else if (inputName == "message") {
    //   setInputs((prev) => ({ ...prev, message: inputs?.message || "" }));
    // }
  };

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default";
    setLayoutName(newLayoutName);
  };

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const onChangeInput = (event) => {
    const inputVal = event.target.value;

    if (inputName == "firstName" || inputName == "lastName") {
      setInputs({ ...inputs, [inputName]: inputVal });

      keyboard.current.setInput(inputVal);
    } else if (inputName == "email") {
      setInputs({ ...inputs, [inputName]: inputVal });

      emailValidation();

      keyboard.current.setInput(inputVal);
    } else if (inputName == "phone") {
      setInputs({ ...inputs, [inputName]: inputVal.replace(/\D/, "") });

      keyboard.current.setInput(inputVal.replace(/\D/, ""));
    } else if (inputName == "message") {
      setInputs({ ...inputs, [inputName]: inputVal });

      keyboard.current.setInput(inputVal);
    }
  };

  return (
    <div
      className="wrapper-2"
      style={{ height: "100vh", position: "relative", overflow: "hidden" }}
    >
      <VideoBox />
      <div className="container-box d-flex align-items-start flex-column">
        <div className="d-flex align-items-center justify-content-between w-100">
          <NavLink to={url + PAGE_THREE}>
            <div className="icon-back-blue"></div>
          </NavLink>
          <div style={{}}>
            <div
              style={
                personalInformation.firstName.length > 0 &&
                personalInformation.lastName.length > 0 &&
                personalInformation.email.length > 0 &&
                personalInformation.phone.length > 0 &&
                email
                  ? { padding: "0.5rem 3rem" }
                  : { opacity: "0.7", padding: "0.5rem 3rem" }
              }
              className={`btn btn-primary big f-right btn-go`}
              onClick={handleValidation}
            >
              Go
            </div>
          </div>
        </div>
        <div
          className="w-100 info_form_div"
          style={{
            maxHeight: "70%",
            overflowY: "scroll",
            marginBottom: 300,
          }}
        >
          <div className="title my-2">
            <h1 className="my-2">Personal information</h1>
          </div>
          <div className="w-100 d-flex justify-content-between mb-4">
            <div className="required p-relative">
              <InputForm
                className="form required name-input"
                placeholder="First name"
                value={personalInformation["firstName"] || ""}
                minLength={1}
                maxLength={50}
                hideFormError={hideFormError}
                onChange={onChangeInput}
                onFocus={() => handleInputFocus("firstName")}
                onBlur={handleInputBlur}
                autoComplete="noop"
                autoFocus={true}
              />
            </div>
            <div className="required p-relative">
              <InputForm
                className="form required name-input"
                placeholder="Last name"
                value={personalInformation["lastName"] || ""}
                minLength={1}
                maxLength={50}
                hideFormError={hideFormError}
                onChange={onChangeInput}
                onFocus={() => handleInputFocus("lastName")}
                onBlur={handleInputBlur}
                autoComplete="noop"
              />
            </div>
          </div>
          <div className="w-100 required p-relative">
            <InputForm
              className={`w-100 form mb-4 ${emailV ? "border-red" : null}`}
              placeholder="Email"
              value={personalInformation["email"] || ""}
              minLength={1}
              maxLength={50}
              hideFormError={hideFormError}
              type="email"
              onChange={onChangeInput}
              onFocus={() => handleInputFocus("email")}
              onBlur={handleInputBlur}
              autoComplete="noop"
            />
          </div>
          <div className="w-100 required p-relative">
            <InputForm
              className="w-100 form required mb-4"
              placeholder="Phone number"
              type="tel"
              value={personalInformation["phone"] || ""}
              minLength={1}
              maxLength={50}
              hideFormError={hideFormError}
              onChange={onChangeInput}
              onFocus={() => handleInputFocus("phone")}
              onBlur={handleInputBlur}
              autoComplete="noop"
            />
          </div>
          <div className="title my-2">
            <h1 className="mb-2">Additional Information</h1>
          </div>
          <InputForm
            className="w-100 form mb-4"
            placeholder="Message"
            value={personalInformation["message"] || ""}
            maxLength={100}
            onChange={onChangeInput}
            onFocus={() => handleInputFocus("message")}
            onBlur={handleInputBlur}
            autoComplete="noop"
          />
        </div>

        <div
          className="keyboard_div"
          style={{
            position: "absolute",
            bottom: 20,
            alignSelf: "center",
            left: 0,
          }}
        >
          <VirtualKeyboard
            keyboardRef={(r) => (keyboard.current = r)}
            layoutName={layoutName}
            layout={{
              default: [
                " 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                " q w e r t y u i o p    ",
                "{lock} a s d f g h j k l ",
                "{shift} z x c v b n m  .  {shift}",
                "@ {space}",
              ],
              shift: [
                "~ ! @  $ % ^ & *   _ + {bksp}",
                " Q W E R T Y U I O P { }  ",
                '{lock} A S D F G H J K L ',
                "{shift} Z X C V B N M   ? {shift}",
                "@ {space}",
              ],
            }}
            display={{
              "{bksp}": "⌫",
              // "{tab}": "Tab",
              "{lock}": "Caps Lock",
              "{enter}": "⏎",
              "{shift}": "Shift",
              "{space}": " ",
            }}
            inputName={inputName}
            onChangeAll={onChangeAll}
            onKeyPress={onKeyPress}
            disableButtonHold
            preventMouseDownDefault
            preventMouseUpDefault
            stopMouseDownPropagation
            stopMouseUpPropagation
            // useTouchEvents
            useMouseEvents
          />
        </div>
      </div>
    </div>
  );
});

export default PageFive;
