import React, {useContext, useState} from 'react';
import {NavLink, Redirect} from "react-router-dom";
import {PAGE_THREE} from "../../routes/consts";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import CarrotVideoBox from "../../components/CarrotVideoBox";

const PageErrorOption3 = observer(() => {
  const {page} = useContext(Context);
  const url = '/' + page.isUrl;
  const [redirect, setRedirect] = useState(false);
  
  setTimeout(() => setRedirect(true), 15000);
  
  if (redirect)
    return <Redirect to={url + PAGE_THREE}/>
  
  return (
    <div className='wrapper'>
      <CarrotVideoBox/>
      <div className="wrapper-bg wrapper-bg-image w-100 d-flex align-items-start flex-column">
        <div className="container" style={{padding: '100px 50px 40px 50px'}}>
          <div className="title">
            <h1>Oops!</h1>
          </div>
          <p className='text-center my-5' style={{fontSize: '21px', fontWeight: '400'}}>
            There was a problem processing your payment please try again with a different card.
          </p>
          <div className='w-100 text-center'>
            <NavLink
              className={`btn btn-secondary w-75`}
              to={url + PAGE_THREE}
            >Enter card details manually</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PageErrorOption3;