import React, {useContext, useEffect, useState} from 'react';
import {NavLink, Redirect, useRouteMatch} from "react-router-dom";
import card from './images/icon-card.png';
import {
  MAIN_ROUTE,
  PAGE_FIVE,
  PAGE_ONE,
  PAGE_THREE,
} from "../../routes/consts";
import { Context } from "../../index";
import NavLinkIconBack from "../../components/NavLinkIconBack";
import { observer } from "mobx-react-lite";
import CarrotVideoBox from "../../components/CarrotVideoBox";
import MagStripeInput from "../../components/MagStripeInput";

const PageOneOption3 = observer(() => {
  const { page, handleCardExpiry, handleCardReadError, handleCardRead } =
    useContext(Context);
  const url = "/" + page.isUrl;
  const match = useRouteMatch();
  const [goNext, setGoNext] = useState(false);

  if (page.amount === "") return <Redirect to={match + MAIN_ROUTE} />;

  if (goNext) return <Redirect to={url + PAGE_FIVE} />;

  return (
    <div className="wrapper">
      <MagStripeInput
        onSubmit={(card) => handleCardRead(card, () => setGoNext(true))}
        onInvalidData={handleCardReadError}
        onCardExpired={handleCardExpiry}
      />
      <CarrotVideoBox />
      <div className="wrapper-bg wrapper-bg-image w-100 d-flex align-items-start flex-column">
        <div className="container">
          <NavLinkIconBack url={url + PAGE_ONE} />
          <div className="title">
            <h1>Swipe the card on the reader</h1>
          </div>
          <div className="p-relative my-4">
            <img src={card} width="332" height="238" alt="" />
            <div className="icon-arrow"></div>
          </div>
          <div className="w-100 text-center ">
            <NavLink className="card-enter" to={url + PAGE_THREE}>
              I want to enter my card details manually
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PageOneOption3;
