import React, { useContext, useEffect, useState } from "react";
import VideoBox from "../../components/VideoBox";
import "./style.css";
import { NavLink, Redirect, useHistory, useRouteMatch } from "react-router-dom";
import {
  MAIN_ROUTE,
  PAGE_FIVE,
  PAGE_ONE,
  PAGE_THREE,
} from "../../routes/consts";
import Card from "./images/icon-card.png";
import Arrow from "./images/icon-arrow.png";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import PageOneOption3 from "../Option3/page_one";
import MagStripeInput from "../../components/MagStripeInput";

const PageTwo = observer(() => {
  const { page, handleCardRead, handleCardReadError, handleCardExpiry } =
    useContext(Context);
  const match = useRouteMatch();
  const url = "/" + page.isUrl;
  const [goNext, setGoNext] = useState(false);

  if (page.amount == "") return <Redirect to={match + MAIN_ROUTE} />;

  if (goNext) return <Redirect to={url + PAGE_FIVE} />;

  const history = useHistory();

  return (
    <div className="wrapper-2 swipe-card">
      <MagStripeInput
        onCardExpired={handleCardExpiry}
        onSubmit={(card) => handleCardRead(card, () => setGoNext(true))}
        onInvalidData={handleCardReadError}
      />
      <VideoBox />
      <div
        className="container-box d-flex align-items-start justify-content-center flex-column"
        style={{ background: "#182F4B" }}
      >
        <NavLink to={url + PAGE_ONE}>
          <div className="icon-back" style={{ marginBottom: 23 }}></div>
        </NavLink>
        <div className="resetButtonDiv">
          <h1> Swipe the card on reader</h1>
          <div
            className={`btn blue-text big btn-primary`}
            style={{ marginLeft: 0, height: 46, marginTop: 13 }}
            onClick={() => history.push(url + MAIN_ROUTE)}
          >
            Reset
          </div>
        </div>
        <p>If something does not work, please notify a team member.</p>
        <div className="w-100 d-flex align-items-center card-box my-4 ">
          <img
            src={Card}
            alt=""
            height="100%"
            style={{
              display: "inline-block",
              marginRight: "58px",
            }}
          />
          <img alt="" src={Arrow} width="99px" height="52px" />
        </div>
        <div className="w-100 text-center " style={{ marginTop: "auto" }}>
          <NavLink to={url + PAGE_THREE}>
            I want to enter my card details manually
          </NavLink>{" "}
        </div>
      </div>
    </div>
  );
});

export default PageTwo;
