import React from 'react';
import PropTypes from "prop-types";

export const InputForm = ({
  className,
  placeholder,
  value,
  textValue,
  minLength,
  maxLength,
  hideFormError,
  ...props
}) => (
  <div className="w-100" style={{ position: "relative" }}>
    <input
      className={`${className} ${
        !hideFormError && value.length == 0 && "border-red"
      }`}
      placeholder={placeholder}
      minLength={minLength}
      maxLength={maxLength}
      value={value}
      type="text"
      {...props}
    />
    {/*{!hideFormError && <FormError text={renderErrorTitle(value, minLength, maxLength)}/>}*/}
  </div>
);

InputForm.propTypes = {
    className: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    hideFormError: PropTypes.bool,
};

InputForm.defaultProps = {
    className: '',
    minLength: 2,
    maxLength: 2,
    hideFormError: true,
};