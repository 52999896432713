import React, {useContext, useState} from 'react';
import {Context} from "../../index";
import logo from './images/icon-logo.png';
import {NavLink, useRouteMatch, Redirect} from "react-router-dom";
import {MAIN_ROUTE} from "../../routes/consts";
import {observer} from "mobx-react-lite";

const DoneOption2 = observer(() => {
    const {personalInformation} = useContext(Context);
    const {page} = useContext(Context);
    const url = '/' + page.isUrl;
    const [redirect, setRedirect] = useState(false);

    page.setClear();
    personalInformation.setClear();
    setTimeout(()=> setRedirect(true), 15000);

    if (redirect)
       return <Redirect to={url + MAIN_ROUTE}/>
    
    return (
      <div className="wrapper blue-bg">
        <div className="w-100 d-flex align-items-start flex-column my-5">
          <div className="p-relative w-100 text-center">
            <img src={page.imageLogo} style={{ width: "180px" }} />
            <NavLink
              to={url + MAIN_ROUTE}
              className="icon-back icon-back-blue-crosse"
            ></NavLink>
          </div>
          <div className="w-100 text-center" style={{ margin: "auto 0" }}>
            <h1
              className="my-5"
              style={{ fontSize: "72px", marginTop: "2rem", color: "#fff" }}
            >
              Thank you
            </h1>
            <p
              className="w-50 text-center"
              style={{ margin: "0 auto", fontSize: "28px", color: "#fff" }}
            ></p>
          </div>

          <div className="w-100 d-flex justify-content-center">
            <NavLink
              className="btn big btn-light"
              style={{ fontWeight: "700", margin: "auto" }}
              to={url + MAIN_ROUTE}
            >
              START OVER
            </NavLink>
          </div>
        </div>
      </div>
    );
});

export default DoneOption2;