import React, { useEffect, useRef } from "react";
import { parse_card } from "../../utils/magStripParser";

export default function MagStripeInput({
  onSubmit = () => null,
  onInvalidData = () => null,
  onCardExpired = () => null,
  containerProps = {},
}) {
  const enterKeyPressCount = useRef(0);
  let cardData = useRef("");
  const resetInput = () => {
    enterKeyPressCount.current = 0;
    cardData.current = "";
  };

  useEffect(() => {
    const handler = (e) => {
      if (e.key === "Escape") {
        resetInput();
      } else if (e.key === "Enter") {
        enterKeyPressCount.current++;
        //if (enterKeyPressCount.current < 2) return;
        try {
          const parsed = parse_card(cardData.current.replaceAll("Shift", ""));
          const invalidDataError = new Error("Invalid data");
          if (!parsed) throw invalidDataError;

          if (!parsed.card_number) throw invalidDataError;

          if (parsed.expired) {
            onCardExpired();
            resetInput();
            return;
          }

          onSubmit(parsed);
          resetInput();
        } catch (e) {
          resetInput();
          onInvalidData(e);
        }
      } else {
        cardData.current += e.key;
      }
    };

    document.addEventListener("keydown", handler);

    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, []);

  return (
    <div
      data-testid="magStripeContainer"
      style={{
        position: "absolute",
        top: "-9999px",
        left: "-9999px",
      }}
      {...containerProps}
    ></div>
  );
}
