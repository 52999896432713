import React, { useContext, useEffect } from "react";
import { Context } from "../../index";
import { MAIN_ROUTE, PAGE_TWO } from "../../routes/consts";
import { NavLink } from "react-router-dom";
import VideoBox from "../../components/VideoBox";
import { observer } from "mobx-react-lite";
import { InputDecimal } from "../../components/Input/InputDecimal";

const CurrencyPage = observer(() => {
  const { page } = useContext(Context);
  const url = "/" + page.isUrl;

  useEffect(() => {
    page.setCurrencyId(page?.availableCurrencies[0]?.currency_id);
    page.setCurrencySymbol(page?.availableCurrencies[0]?.currency?.symbol);
  }, []);

  const inputChange = (e) => {
    page.setAmount(e);
  };

  const handleCurrecyChange = (e) => {
    let currency = JSON.parse(e.target.value);
    page.setCurrencyId(currency?.id);
    page.setCurrencySymbol(currency?.symbol);
  };

  return (
    <div className="wrapper-2">
      <VideoBox />
      <div className="container-box d-flex flex-column">
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              margin: "auto 0px",
            }}
          >
            <div className="title">
              <h1>Select the currency </h1>
            </div>
            <div>
              <select
                name="currecy_list"
                onChange={(e) => handleCurrecyChange(e)}
              >
                {page.availableCurrencies?.map((item, index) => {
                  return (
                    <option value={JSON.stringify(item.currency)} key={index}>
                      {item?.currency?.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {page.donationOptions && page.donationOptions.length ? (
              <>
                <div className="w-100 or">
                  <hr />
                  <div>or</div>
                </div>
                <div className="amount">
                  {page.donationOptions.map((d, i) => (
                    <div key={i} className="amount-item">
                      <div className="box" onClick={() => inputChange(d)}>
                        <div className="icon">
                          <span>{page.currencySymbol}</span>
                        </div>
                        <p>{`${page.currencySymbol} ${d}`}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>

          <div
            className="w-100 p-relative"
            style={{
              marginTop: "0.75rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div className="change-donation-type-text">
              <NavLink to={url + MAIN_ROUTE}>Change donation type</NavLink>
            </div>
            <NavLink
              style={{
                pointerEvents:
                  page.amount !== "" && parseFloat(page.amount) !== 0
                    ? undefined
                    : "none",
              }}
              className={`btn btn-primary big f-right btn-go ${
                page.amount.length > 0 ? "" : "disable"
              }`}
              to={url + PAGE_TWO}
            >
              Go
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CurrencyPage;
