import { makeAutoObservable } from "mobx";

export default class PageStore {
  constructor() {
    this._layoutName = "";
    this._kioskSlug = "";
    this._kioskId = "";
    this._availableCurrencies = [];
    this._currencyId = "";
    this._currencySymbol = "";
    this._paymentId = "";
    this._paymentName = "";
    this._logo = null;
    this._pageBackground = null;
    this._donationOptions = [];
    this._groups = [];
    this._groupId = null;
    this._subscriptionDurationId = '1';
    this._donate = false;
    this._amount = "";
    this._cardNumber = "";
    this._cardDate = "";
    this._cardCvc = "";
    makeAutoObservable(this);
  }

  setClear() {
    this._donate = false;
    this._amount = "";
    this._cardNumber = "";
    this._cardDate = "";
    this._cardCvc = "";
  }

  get isUrl() {
    return this._kioskSlug;
  }

  setIsUrl(value) {
    this._kioskSlug = value;
  }

  get availableCurrencies() {
    return this._availableCurrencies;
  }

  setAvailableCurrencies(value) {
    this._availableCurrencies = value;
  }

  get currencyId() {
    return this._currencyId;
  }

  setCurrencyId(value) {
    this._currencyId = value;
  }

  get currencySymbol() {
    return this._currencySymbol;
  }

  setCurrencySymbol(value) {
    this._currencySymbol = value;
  }

  get paymentId() {
    return this._paymentId;
  }

  setPaymentId(value) {
    this._paymentId = value;
  }

  get paymentName() {
    return this._paymentName;
  }

  setPaymentName(value) {
    this._paymentName = value;
  }

  get isPage() {
    return this._layoutName;
  }

  setIsPage(value) {
    this._layoutName = value;
  }

  get donate() {
    return this._donate;
  }

  setDonate(value) {
    this._donate = value;
  }

  get amount() {
    return this._amount;
  }

  setAmount(value) {
    this._amount = value;
  }

  get cardNumber() {
    return this._cardNumber;
  }

  setCardNumber(value) {
    this._cardNumber = value;
  }

  get cardDate() {
    return this._cardDate;
  }

  setCardDate(value) {
    this._cardDate = value;
  }

  get cardCvc() {
    return this._cardCvc;
  }

  setCardCvc(value) {
    this._cardCvc = value;
  }

  get kioskId() {
    return this._kioskId;
  }

  setKioskId(value) {
    this._kioskId = value;
  }

  get donationOptions() {
    return this._donationOptions;
  }

  setDonationOptions(value) {
    this._donationOptions = value;
  }

  get groups() {
    return this._groups;
  }

  setGroups(value) {
    this._groups = value;
  }

  get groupId() {
    return this._groupId;
  }

  setGroupId(value) {
    this._groupId = value;
  }

  get subscriptionDurationId() {
    return this._subscriptionDurationId;
  }

  setSubscriptionDurationId(value) {
    this._subscriptionDurationId = value;
  }

  get imageLogo() {
    return this._logo;
  }

  setImageLogo(value) {
    this._logo = value;
  }

  get imageBackground() {
    return this._pageBackground;
  }

  setImageBackground(value) {
    this._pageBackground = value;
  }
}
