import {$authHost, $host} from "./index";

export const sendDonation = async (data) => {
  console.log("sending", data);
  const response = await $host.post("api/donations", data);
  return response;
};

export const getKioskDataBySlug = async (url) => {
  const response = await $host.get(`api/kiosks/bySlug/${url}`);
  return response;
};

export const getKioskCurrencies = async (url) => {
  const response = await $host.get(`api/currencies`);
  return response;
};